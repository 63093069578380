import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form, FloatingLabel, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/TEDxConegliano_01_Black.png';
import instagram from '../../assets/instagram.svg';
import linkedin from '../../assets/linkedin.svg';
import youtube from '../../assets/youtube.svg';
import facebook from '../../assets/facebook.svg';
import CookieBot from 'react-cookiebot';
import jsonp from 'jsonp';
import ted from '../../assets/ted.webp';
import tedx from '../../assets/tedx.webp';



export default function FooterBlock() {

    const [privacy, setPrivacy] = useState(false);
    const [email, setEmail] = useState('');
    const [fail, setFail] = useState('');
    const [success, setSuccess] = useState('');

    function registerEmail() {
        if(email.length > 3 && privacy) {
            const url = 'https://tedxconegliano.us22.list-manage.com/subscribe/post?u=63e426cef359070d55f810936&amp;id=54014b720a&amp;f_id=0021cae1f0';
            jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
                const { msg, result } = data
                // do something with response
                setSuccess(msg);
                setEmail('')
                setFail('')

                setTimeout(() => {
                    setSuccess('')
                }, "5000");
            });
        }
        else {
            setFail('Devi inserire una mail valida ed accettare la Privacy Policy prima di proseguire.')
            setSuccess('')

            setTimeout(() => {
                setFail('')
            }, "5000");
        }
    }

    return (
        <>
            <Container fluid id="footer">
                <Container fluid='md' className='mt-4 p-5'>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <div style={{ maxWidth: 400 }} className='p-2 mb-5'>
                                <h2 className='mb-4'>Rimani nel loop!<br />Iscriviti alla Newsletter</h2>
                                <p className='small'>Ti invieremo news e aggiornamenti dal mondo TEDxConegliano. Non riceverai spam, promesso!</p>
                                <FloatingLabel label="La tua email" className="mb-3">
                                    <Form.Control id="mce-EMAIL" value={email} type="email" placeholder="la tua mail" onChange={(event) => setEmail(event.target.value)} />
                                </FloatingLabel>
                                <Form.Check
                                    type="switch"
                                    label={<span className='small'>Accetto la Privacy Policy</span>}
                                    size="sm"
                                    onChange={(event) => setPrivacy(event.target.checked)}
                                    checked={(privacy) || false}
                                />
                                <div className='mt-5'>
                                    <span className='button-52 m-auto' onClick={() => registerEmail()}>
                                        Registrati
                                    </span>
                                </div>
                                {fail.length > 3 && (
                                    <Alert variant='danger' className='mt-4 small p-3'>
                                        {fail}
                                    </Alert>
                                )}
                                {success.length > 3 && (
                                    <Alert variant='success' className='mt-4 small p-3'>
                                        {success}
                                    </Alert>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className='p-2 mb-5'>
                                <h4 className='mb-4'>Volontari</h4>
                                <p className='small'>
                                    Vuoi far parte dei nostri?<br/>
                                    Siamo sempre alla ricerca di volontari!
                                    <br />
                                    info@tedxconegliano.it
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className='p-2 mb-5'>
                                <h4 className='mb-4'>Contattaci</h4>
                                <p className='small'>info@tedxconegliano.it</p>
                                <p className='small'>
                                    <a href='https://www.instagram.com/tedx_conegliano?igsh=MWJ4emFhM25qOWZtcg==' target='_blank'>
                                        <Image src={instagram} width={30} className='me-3' />
                                    </a>
                                    <a href='https://www.facebook.com/share/K6dumWcpwiWamT1x/?mibextid=LQQJ4d' target='_blank'>
                                        <Image src={facebook} width={30} className='me-3' />
                                    </a>
                                    <a href='https://www.linkedin.com/company/tedx-conegliano/' target='_blank'>
                                        <Image src={linkedin} width={30} className='me-3' />
                                    </a>
                                    <a href='https://www.youtube.com/@TEDxConegliano' target='_blank'>
                                        <Image src={youtube} width={30} className='me-3' />
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid id="footer">
                <Container fluid='md' className='mt-4 p-5'>
                    <Row>
                        <Col xs={12} md={4} className='mb-4'>
                            <Image src={ted} height={20} className='mb-4' />
                            <p className='small'>
                                TED è un'organizzazione senza scopo di lucro e apartitica dedicata alla scoperta, al dibattito e alla diffusione di idee che stimolino la conversazione, approfondiscano la comprensione e guidino un cambiamento significativo. La nostra organizzazione è dedicata alla curiosità, alla ragione, alla meraviglia e alla ricerca della conoscenza, senza un ordine del giorno. Accogliamo persone di ogni disciplina e cultura che cercano una comprensione più profonda del mondo e una connessione con gli altri, e invitiamo tutti a impegnarsi con le idee e ad attivarle nella propria comunità.
                            </p>
                        </Col>
                        <Col xs={12} md={4} className='mb-4'>
                            <Image src={tedx} height={20} className='mb-4' />
                            <p className='small'>
                                <b>TEDx, x = evento organizzato in modo indipendente</b><br/>
                                Nello spirito della scoperta e della diffusione delle idee, TEDx è un programma di eventi locali auto-organizzati che riuniscono le persone per condividere un'esperienza simile a quella di TED. In un evento TEDx, i video dei TED Talks e gli oratori dal vivo si combinano per suscitare discussioni e connessioni profonde. Questi eventi locali auto-organizzati sono marchiati TEDx, dove x = evento TED organizzato in modo indipendente. La TED Conference fornisce una guida generale per il programma TEDx, ma i singoli eventi TEDx sono auto-organizzati. (Nel rispetto di alcune regole e normative).
                            </p>
                        </Col>
                        <Col xs={12} md={4} className='mb-4'>
                            <Image src={logo} height={20} className='mb-4' />
                            <p className='small'>
                                TEDxConegliano è un'iniziativa ambiziosa che riunisce giovani talenti eterogenei, uniti dalla passione per l'innovazione e dalla visione di un futuro migliore per la propria città, Patrimonio Unesco. Attraverso la condivisione di idee innovative, la valorizzazione delle eccellenze e la promozione dell'inclusività, TEDxConegliano mira a creare un ambiente stimolante e a coltivare una comunità di pensatori audaci.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='mt-3 pt-5 pb-5 text-start' style={{backgroundColor: '#eee'}}>
                <Container fluid='md' className='pe-5 ps-5'>
                    <Row className=''>
                        <Col xs={6} md={6}>
                            <Image src={logo} width={170} />
                        </Col>
                        <Col xs={6} md={6}>
                            <p className='small'>© 2024 TEDxConegliano<br/>Associazione TEDxConegliano APS C.F./Partita IVA 05488530261.<br/><br/><span className='text-muted'>Powered by <a href='https://gruppoyec.com' target='_blank'>Gruppo Yec Società Benefit</a>.</span></p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <CookieBot domainGroupId={'54599b09-c214-48c1-8fa5-47e8be01858b'} />
        </>
    );
};