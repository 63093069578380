import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lottie from 'react-lottie';
import mani from '../../assets/mani.jpg';
import stradaghiaia from '../../assets/stradaghiaia.jpg';
import villagera1 from '../../assets/villagera1.jpg';
import villagera2 from '../../assets/villagera2.jpg';
import logoAnimato from '../../assets/lotties/logoAnimato';
import logoAnimato_bianco from '../../assets/lotties/logoAnimato_bianco';
import AlessandraPattelli from '../../assets/heritage/Speaker/AlessandraPattelli.jpg';
import CarlosDosSantos from '../../assets/heritage/Speaker/CarlosDosSantos.jpg';
import FrancescoPase from '../../assets/heritage/Speaker/FrancescoPase.jpg';
import LucianaCremonese from '../../assets/heritage/Speaker/LucianaCremonese.jpg';
import MarioMazzer from '../../assets/heritage/Speaker/MarioMazzer.jpg';
import RobertoBertazzon from '../../assets/heritage/Speaker/RobertoBertazzon.jpg';
import SaraSech from '../../assets/heritage/Speaker/SaraSech.jpg';
import VittoriaFerragamo from '../../assets/heritage/Speaker/VittoriaFerragamo.jpg';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';



export default function Heritage() {

    const cache0 = {};
    function importAll0(r) {
        r.keys().forEach((key) => (cache0[key] = r(key)));
    }
    importAll0(require.context("../../assets/heritage/00_Main_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos0 = Object.entries(cache0).map(module => module[1]);


    const cache1 = {};
    function importAll1(r) {
        r.keys().forEach((key) => (cache1[key] = r(key)));
    }
    importAll1(require.context("../../assets/heritage/01_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos1 = Object.entries(cache1).map(module => module[1]);


    const cache2 = {};
    function importAll2(r) {
        r.keys().forEach((key) => (cache2[key] = r(key)));
    }
    importAll2(require.context("../../assets/heritage/02_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos2 = Object.entries(cache2).map(module => module[1]);


    const cache3 = {};
    function importAll3(r) {
        r.keys().forEach((key) => (cache3[key] = r(key)));
    }
    importAll3(require.context("../../assets/heritage/03_Patrocinio/", false, /\.(png|jpe?g|svg)$/));
    const logos3 = Object.entries(cache3).map(module => module[1]);




    const logoLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: logoAnimato,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const logoLottieOptions2 = {
        loop: true,
        autoplay: true,
        animationData: logoAnimato_bianco,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <>
            <Helmet>
                <title>Heritage</title>
                <meta name="description" content="TEDxConegliano - Edizione: Heritage" />
            </Helmet>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-light align-items-end' style={{ backgroundColor: '#eee', backgroundImage: 'url(' + mani + ')' }}>
                <Container fluid="md" className='text-start p-5 text-white' style={{ zIndex: 1 }}>
                    <h5>2024</h5>
                    <h1 className='mt-5 mb-5' style={{ maxWidth: 700 }}>
                        HERITAGE - Patrimonio Culturale<br />
                        29 Giugno 2024
                    </h1>

                    <p className='lead' style={{ maxWidth: 700 }}>
                        Un patrimonio fatto non soltanto di letteratura, arte e monumenti, ma anche di
                        tradizioni imparate dai nostri nonni, di storie raccontate ai nostri figli, di sapori e
                        profumi tramandati dai maestri. Il passato come una memoria che ci consente
                        di costruire il futuro.
                    </p>

                    <div className='mt-5 mb-5'>
                        <a href='#rivivi_evento'>
                            <span className='button-52-light brown m-auto'>
                                Rivivi l'evento
                            </span>
                        </a>
                    </div>

                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 height-100'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} lg={5}>
                            <h5 style={{ fontWeight: 600, color: '#a98e75' }}>TEDxConegliano</h5>
                            <h3 className='mt-5 mb-5' style={{ maxWidth: 700 }}>
                                HERITAGE - Patrimonio Culturale
                                <br />
                                Da oggi per un domani
                            </h3>

                            <p className='mb-5'>
                                Per il nostro primo evento, esploreremo il tema del
                                patrimonio culturale in un contesto unico: Conegliano.
                                Qui, il passato e il presente si fondono in un intricato
                                intreccio di esperienze, cultura, arte e tradizioni.
                            </p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={6} className='text-center'>
                            <Lottie
                                options={logoLottieOptions}
                                height={430}
                                width={330}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-black' style={{ backgroundColor: '#eee', backgroundImage: 'url(' + stradaghiaia + ')' }}>
                <Container fluid="md" className='text-start p-5 text-white' style={{ zIndex: 1 }}>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5 style={{ fontWeight: 600, color: '#a98e75' }}>TEDxConegliano</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <h3>
                                Ci immergeremo nell'esperienza del
                                passato per trarre ispirazione e guida per il futuro.
                            </h3>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p>
                                Esploreremo come il territorio stesso,
                                attraverso la sua storia, la sua arte e la sua
                                cultura, abbia plasmato la nostra identità,
                                influenzato il mondo che viviamo oggi e
                                costruiamo per il domani.
                            </p>
                            <p>
                                Mettendo in risalto l'importanza del vino, che per
                                Conegliano rappresenta non solo una tradizione, ma anche
                                una storia di innovazione e eccellenza, ci impegniamo a
                                guardare indietro per poter influenzare il nostro futuro.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100' style={{ backgroundColor: '#a98e75' }}>
                <Container fluid="md" className='text-center p-5 text-white' style={{ zIndex: 1 }}>

                    <Lottie
                        options={logoLottieOptions2}
                        height={350}
                        width={260}
                    />

                    <h1 className='mt-5 mb-5'>
                        Da ieri per un domani
                    </h1>

                    <p className='m-auto' style={{ maxWidth: 700 }}>
                        Questo evento sarà una celebrazione di ciò che siamo diventati e una riflessione su come
                        possiamo utilizzare il nostro patrimonio culturale per aprire le menti ad idee nuove e trasformative,
                        creando un futuro più luminoso per la nostra comunità e oltre.
                    </p>

                    <div className='mt-5 mb-5'>
                        <a href="#rivivi_evento">
                            <span className='button-52-light brown m-auto'>
                                Rivivi l'evento
                            </span>
                        </a>
                    </div>

                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={3} className='pb-5'>
                            <h5 style={{ fontWeight: 600, color: '#a98e75' }}>SPEAKER</h5>
                            <p>Persone e idee che possono contribuire a cambiare la nostra visione e consentirci di preservare e valorizzare il nostro patrimonio culturale</p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={8} className='pb-5'>
                            <Row className='mt-5'>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={VittoriaFerragamo} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Vittoria Ferragamo</p>
                                    <p className='text-muted'>
                                        Responsabile Sostenibilità de “Il
                                        Borroˮ Progetto che cura con la
                                        famiglia che ha permesso di ridare
                                        vita ad un borgo secolare
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={MarioMazzer} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Mario Mazzer</p>
                                    <p className='text-muted'>
                                        Architetto di fama internazionale,
                                        noto per aver ricevuto numerosi
                                        riconoscimenti a livello mondiale
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={RobertoBertazzon} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Roberto Bertazzon</p>
                                    <p className='text-muted'>
                                        The Frog Man - Artista, scultore e
                                        Concept Designer.<br/>
                                        Grazie alla sua arte da vita e racconta gli animali
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={AlessandraPattelli} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Alessandra Patelli</p>
                                    <p className='text-muted'>
                                        Olimpionica e Medico dello sport.<br/>
                                        Canottiera italiana ha partecipato ai Giochi olimpici di Rio de Janeiro e Tokyo.
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={LucianaCremonese} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Luciana Cremonese</p>
                                    <p className='text-muted'>
                                        Presidente di Sol.Co.<br/>
                                        Impresa sociale che dal 1992 si
                                        occupa di inserimento al lavoro di
                                        persone con disagio mentale
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={FrancescoPase} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Francesco Pase</p>
                                    <p className='text-muted'>
                                        Guida il team AI nella ricerca e
                                        nello sviluppo di nuovi modelli di
                                        intelligenza artificiale per la fisica e
                                        l'ingegneria.
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={CarlosDosSantos} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Carlos Veloso Dos Santos</p>
                                    <p className='text-muted'>
                                        Amministratore delegato Amorim Cork Italia.<br/>
                                        Il benessere delle persone come chiave del successo
                                        aziendale
                                    </p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={SaraSech} width={'100%'} />
                                    <p style={{ fontWeight: 600 }} className='lead mt-4 mb-1'>Sara Sech</p>
                                    <p className='text-muted'>
                                        Infermiera presso Ulss2.<br/>
                                        Specializzata in cure palliative
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={3} className='pb-5'>
                            <h5 style={{ fontWeight: 600, color: '#a98e75' }}>29 GIUGNO 2024</h5>
                            <p>Un tramonto nel Patrimonio UNESCO</p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={8} className='pb-5'>
                            <Row>
                                <Col xs={12} lg={4} className='p-4' style={{ backgroundColor: '#f3f3f3', border: '10px solid #fff' }}>
                                    <p style={{ color: '#a98e75' }} className='lead mt-4 mb-1 small'>ACCREDITI</p>
                                    <h2 style={{ fontWeight: 500, color: '#a98e75' }} className='mt-5'>16:00</h2>
                                    <p className='text-muted small'>
                                        Accoglienza presso lʼingresso di Villa Gera
                                    </p>
                                </Col>
                                <Col xs={12} lg={4} className='p-4' style={{ backgroundColor: '#f3f3f3', border: '10px solid #fff' }}>
                                    <p style={{ color: '#a98e75' }} className='lead mt-4 mb-1 small '>APERITIVO DI BENVENUTO</p>
                                    <h2 style={{ fontWeight: 500, color: '#a98e75' }} className='mt-5'>16:30</h2>
                                    <p className='text-muted small'>
                                        La condivisione è fondamentale per crescere, si può fare solo insieme
                                    </p>
                                </Col>
                                <Col xs={12} lg={4} className='p-4' style={{ backgroundColor: '#f3f3f3', border: '10px solid #fff' }}>
                                    <p style={{ color: '#a98e75' }} className='lead mt-4 mb-1 small'>ESPERIENZA IN VILLA</p>
                                    <h2 style={{ fontWeight: 500, color: '#a98e75' }} className='mt-5'>17:00</h2>
                                    <p className='text-muted small'>
                                        Visista Guidata allʼinterno di Villa Gera - con il biglietto HERITAGE
                                    </p>
                                </Col>
                                <Col xs={12} lg={4} className='p-4' style={{ backgroundColor: '#f3f3f3', border: '10px solid #fff' }}>
                                    <p style={{ color: '#a98e75' }} className='lead mt-4 mb-1 small'>TALKS</p>
                                    <h2 style={{ fontWeight: 500, color: '#a98e75' }} className='mt-5'>18:00</h2>
                                    <p className='text-muted small'>
                                        TEDxConegliano Talks
                                    </p>
                                </Col>
                                <Col xs={12} lg={4} className='p-4' style={{ backgroundColor: '#f3f3f3', border: '10px solid #fff' }}>
                                    <p style={{ color: '#a98e75' }} className='lead mt-4 mb-1 small'>CONCLUSIONI</p>
                                    <h2 style={{ fontWeight: 500, color: '#a98e75' }} className='mt-5'>21:30</h2>
                                    <p className='text-muted small'>
                                        Ringraziamenti ei saluti
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-black' style={{ backgroundColor: '#eee', backgroundImage: 'url(' + villagera1 + ')' }}>
                <Container fluid="md" className='text-center p-5 text-white' style={{ zIndex: 1 }}>
                    <h5 style={{ fontWeight: 600, color: '#a98e75' }}>LOCATION</h5>
                    <h1 className='mt-5 mb-5'>
                        Villa Gera
                    </h1>

                    <p className='m-auto' style={{ maxWidth: 700 }}>
                        La villa nata oltre due secoli fa, per volontà di Bartolomeo Gera con lo scopo di
                        promuovere e divulgare lʼarte in città, è il palcoscenico naturale.
                        Qui si uniscono due grandi temi caratterizzanti la città: lʼarte e la viticoltura.
                    </p>

                    <div className='mt-5 mb-5'>
                        <a href='#rivivi_evento'>
                            <span className='button-52-light brown m-auto'>
                                Rivivi l'evento
                            </span>
                        </a>
                    </div>

                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 height-100'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} lg={5}>
                            <h5 style={{ fontWeight: 600, color: '#a98e75' }}>TEDxConegliano</h5>
                            <p className='mt-5 mb-5 lead'>
                                Immersa nella bellezza dei vitigni di Prosecco
                                del paesaggio collinare, Villa Gera rappresenta
                                un gioiello architettonico veneto con una storia
                                unica. Questo edificio, ricco di fascino e storia,
                                incarna perfettamente il patrimonio UNESCO
                                delle colline del Prosecco.
                            </p>

                            <p className='mb-5'>
                                Circondata da un'atmosfera di tranquillità e bellezza
                                naturale, la villa offre un ambiente senza tempo per ispirare
                                e condividere idee innovative. Con i suoi eleganti saloni e
                                giardini ben curati, Villa Gera è il luogo ideale per ospitare
                                un evento che celebra la cultura, la creatività e il progresso.
                                <br /><br />
                                Adagiata sulla collina del Castello, Villa Gera si trova
                                allʼinterno dellʼantica cinta muraria del Castello e da qui
                                domina la città di Conegliano.
                                Il progetto dello Jappelli presenta una facciata di matrice
                                palladiana rivolta verso valle, con un colonnato imponente
                                sormontato da un timpano scolpito da Marco Casagrande.
                            </p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={6} className='text-center'>
                            <Image src={villagera2} width={'100%'} />
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0' style={{ background: '#a98e75' }}>
                <Container fluid="md" className='text-center p-5 text-white'>
                    <h5>PARTNER</h5>
                    <h1 className='mt-5 mb-5 ms-auto me-auto' style={{ maxWidth: 760 }}>
                        I nostri partner, credono nel territorio e nei talenti che esso esprime.
                    </h1>

                    <p className='m-auto' style={{ maxWidth: 700 }}>
                        Con il loro sostegno, costruiamo insieme un futuro più luminoso, in cui la cultura è al centro del progresso e della crescita della comunità
                    </p>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0'>
                <Container fluid="md" className='text-center p-5'>
                    <h5 style={{ color: '#a98e75' }}>CON IL PATROCINIO DI</h5>
                    <Row className='justify-content-center'>
                        {logos3.map((logo, i) => {
                            return (
                                <Col xs={6} md={2} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>

                    <h5 style={{ color: '#a98e75' }} className='mt-5'>I NOSTRI PARTNER</h5>

                    <Row className='justify-content-center'>
                        {logos0.map((logo, i) => {
                            return (
                                <Col xs={6} md={4} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>

                    <hr/>

                    <Row className='justify-content-center'>
                        {logos1.map((logo, i) => {
                            return (
                                <Col xs={6} md={3} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>

                    <hr/>

                    <Row className='justify-content-center'>
                        {logos2.map((logo, i) => {
                            return (
                                <Col xs={4} md={2} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0' style={{ background: '#a98e75' }}>
                <Container fluid="md" className='text-center p-5 text-white'>
                    <h5>I TALK DI QUESTA EDIZIONE</h5>

                    <Row className='mt-5'>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="3rFVFbm4ftQ"
                                title="Francesco Pase | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Robot, giraffe, gru: l'IA tra limiti e futuro | Francesco Pase</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="cLv5lqc7woc"
                                title="Luciana Cremonese | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Dal dolore alla rinascita: il potere del lavoro | Luciana Cremonese</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="IwNlwGRNG1U"
                                title="Mario Mazzer | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>L’architettura determina la qualità della nostra esistenza | Mario Mazzer</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="bkmqdilw3JQ"
                                title="Roberto Bertazzon | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Come Rane sorde... | Roberto Bertazzon</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="2g-dQ4gNRT0"
                                title="Alessandra Patelli | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>La formula magica non esiste | Alessandra Patelli</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="0gmc7mWIKyM"
                                title="Vittoria Ferragamo | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Rallentare per riscoprire e riscoprirsi | Vittoria Ferragamo</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="mxF-iByTH48"
                                title="Sara Sech | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>La cura come patrimonio | Sara Sech</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="uFv8l4iX-Ik"
                                title="Carlos Veloso Dos Santos | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Felicità e sostenibilità: il futuro del lavoro | Carlos Veloso Dos Santos</p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0' style={{ background: '#fff' }} id="rivivi_evento">
                <Container fluid="md" className='text-center p-5 text-muted'>
                    <h5>RIVIVI L'EVENTO</h5>
                    <div className='mt-5'>
                        <LiteYouTubeEmbed
                            id="0CU_q0FwqAw"
                            title="TEDxConegliano - HERITAGE 2024"
                            poster="maxresdefault"
                            aspectHeight='140'
                            aspectWidth='384'
                        />
                    </div>

                </Container>
            </Container>

        </>
    );
};