import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Image, Row, Col } from 'react-bootstrap';
import home1 from '../assets/home1.jpeg';
import piazzacima from '../assets/piazzacima.jpg';
import castello from '../assets/castello.jpg';
import castello2 from '../assets/castello2.jpg';
import pozzo from '../assets/pozzo.jpg';
import viaxx from '../assets/viaxx.jpg';
import mani from '../assets/mani.jpg';
import teamTED from '../assets/TeamTEDxConegliano.jpg';
import icona1 from '../assets/Icona_1.svg';
import icona2 from '../assets/Icona_2.svg';
import icona3 from '../assets/Icona_3.svg';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';



export default function Home() {

    const cache0 = {};
    function importAll0(r) {
        r.keys().forEach((key) => (cache0[key] = r(key)));
    }
    importAll0(require.context("../assets/heritage/00_Main_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos0 = Object.entries(cache0).map(module => module[1]);


    const cache1 = {};
    function importAll1(r) {
        r.keys().forEach((key) => (cache1[key] = r(key)));
    }
    importAll1(require.context("../assets/heritage/01_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos1 = Object.entries(cache1).map(module => module[1]);


    return (
        <>
            <Helmet>
                <title>TEDxConegliano</title>
                <meta name="description" content="TEDxConegliano" />
            </Helmet>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-red align-items-end' style={{ backgroundColor: '#eee', backgroundImage: 'url(' + piazzacima + ')' }}>
                <Container fluid="md" className='text-start p-5 text-white' style={{ zIndex: 1 }}>
                    <h5>TEDxConegliano</h5>
                    <h1 className='mt-5 mb-5' style={{ maxWidth: 700 }}>
                        Dove l'incanto della nostra città, ora Patrimonio UNESCO, si unisce alla forza delle idee.
                    </h1>

                    <p style={{ maxWidth: 700 }} className='lead'>
                        Attraverso sfide e innovazioni, ci dobbiamo far ispirare, costruendo
                        una comunità di pensatori audaci che arricchiscono il tessuto
                        culturale e sociale del nostro territorio
                    </p>

                    <div className='mt-5 mb-5'>
                        <Link to="/edition/heritage">
                            <span className='button-52-light m-auto'>
                                Next Event
                            </span>
                        </Link>
                    </div>
                </Container>
            </Container>
            
{/*
            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 align-items-end'>
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
                    <iframe style={{ width: '100%', height: '100%'}} frameborder='0' src="https://www.youtube.com/embed/0CU_q0FwqAw?&autoplay=1&mute=1&rel=0&loop=1&showinfo=0&controls=0&modestbranding=1"></iframe>
                </div>
                <Container fluid="md" className='text-start p-5 text-white' style={{ zIndex: 1 }}>
                    <h5>TEDxConegliano</h5>
                    <h1 className='mt-5 mb-5' style={{ maxWidth: 700 }}>
                        Dove l'incanto della nostra città, ora Patrimonio UNESCO, si unisce alla forza delle idee.
                    </h1>

                    <p style={{ maxWidth: 700 }} className='lead'>
                        Attraverso sfide e innovazioni, ci dobbiamo far ispirare, costruendo
                        una comunità di pensatori audaci che arricchiscono il tessuto
                        culturale e sociale del nostro territorio
                    </p>

                    <div className='mt-5 mb-5'>
                        <Link to="/edition/heritage">
                            <span className='button-52-light m-auto'>
                                Next Event
                            </span>
                        </Link>
                    </div>
                </Container>
            </Container>
*/}


            <Container fluid className='mt-5 pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={4} className='pb-5'>
                            <Image src={icona1} height={100} className='mb-5' />
                            <p>
                                L'eccellenza del territorio si unisce alla visione globale di TED
                            </p>
                        </Col>
                        <Col xs={12} lg={4} className='pb-5'>
                            <Image src={icona2} height={100} className='mb-5' />
                            <p>
                                L'eredità artistica e storica di Conegliano è il trampolino di lancio per un'innovazione senza confini
                            </p>
                        </Col>
                        <Col xs={12} lg={4} className='pb-5'>
                            <Image src={icona3} height={100} className='mb-5' />
                            <p>
                                Il suggestivo paesaggio e il ricco patrimonio di Conegliano fungono da amplificatore per le nostre idee
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 height-100'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} lg={6} className='text-center'>
                            <Image src={pozzo} width={'100%'} className='mb-5' />
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5}>
                            <h5 style={{ color: '#eb0028' }}>TEDxConegliano</h5>
                            <h3 className='mt-5 mb-5' style={{ maxWidth: 700 }}>Perchè proprio a Conegliano?</h3>

                            <p className='mb-5 lead'>Conegliano è un luogo intriso di storia, cultura e innovazione, il che lo rende la cornice perfetta per un evento TEDx.</p>
                            <p style={{ maxWidth: 600 }}>
                                La città e le sue colline, recentemente insigniti del
                                prestigioso titolo di Patrimonio UNESCO, offrono un
                                ambiente unico che incarna la fusione tra tradizione e
                                modernità.
                            </p>

                            <div className='mt-5 mb-5'>
                                <Link to="/edition/heritage">
                                    <span className='button-52 m-auto'>
                                        Next Event
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-black' style={{ backgroundColor: '#eee', backgroundImage: 'url(' + castello2 + ')' }}>
                <Container fluid="md" className='text-start p-5 text-white' style={{ zIndex: 1 }}>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5 style={{ color: '#eb0028' }}>TEDxConegliano</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <p className='lead'>
                                Il format TED vuole essere un luogo
                                di incontro e di stimolo verso un
                                futuro in costante evoluzione, così da
                                preparare la città e tutti i suoi attori
                                alla sfide future che ella stessa si è
                                data.
                            </p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p>
                                Un palco che ospiterà speaker locali che rendono grande la
                                città nel mondo ma anche personalità che possono dare
                                una visione e uno stimolo nuovo alla città.
                                <br /><br />
                                Stimolare la città ad innovarsi, aprirsi e rendersi attrattiva
                                anche verso i giovani attraverso la Valorizzazione dei tanti
                                talenti che ci vivono, spesso però costretti a sposarsi per
                                trovare la loro piena maturità ma con la volontà di ritornare
                                nella loro città natale.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5 style={{ color: '#eb0028' }}>TEDxConegliano</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <Image src={viaxx} width={'100%'} />
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p className='mb-5 lead'>
                                Immersa tra paesaggi mozzafiato e circondata
                                da un ricco patrimonio artistico, Conegliano
                                deve attrarre menti creative e appassionate da
                                tutto il mondo.
                            </p>
                            <p>
                                La sua posizione strategica nel cuore del Veneto, rinomato
                                per la sua eccellenza enogastronomica e il suo fervore
                                culturale, la rende un crocevia ideale per l'incontro e lo
                                scambio di idee.
                                <br /><br />
                                Organizzare un evento TEDx a Conegliano non solo
                                celebra e condivide la bellezza e l'innovazione di questa
                                città, ma anche offre un trampolino per idee nuove e
                                fresche che possono ispirare e trasformare il territorio,
                                promuovendo un dialogo costruttivo che porta alla crescita
                                e al miglioramento della comunità.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-light' style={{ backgroundColor: '#eee', backgroundImage: 'url(' + mani + ')' }}>
                <Container fluid="md" className='text-center p-5 text-white' style={{ zIndex: 1 }}>
                    <h5>Da ieri per un domani</h5>
                    <h1 className='mt-5 mb-5'>
                        HERITAGE - Patrimonio Culturale
                    </h1>

                    <p className='m-auto' style={{ maxWidth: 700 }}>
                        Un patrimonio fatto non soltanto di letteratura, arte e monumenti, ma anche di tradizioni imparate
                        dai nostri nonni, di storie raccontate ai nostri figli, di sapori e profumi tramandati dai maestri. Il
                        passato come una memoria che ci consente di costruire il futuro.
                    </p>

                    <div className='mt-5 mb-5'>
                        <Link to="/edition/heritage">
                            <span className='button-52-light brown m-auto'>
                                Scopri
                            </span>
                        </Link>
                    </div>

                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0' style={{ background: '#a98e75' }}>
                <Container fluid="md" className='text-center p-5 text-white'>
                    <h5>I TALK DI QUESTA EDIZIONE</h5>

                    <Row className='mt-5'>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="3rFVFbm4ftQ"
                                title="Francesco Pase | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Robot, giraffe, gru: l'IA tra limiti e futuro | Francesco Pase</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="cLv5lqc7woc"
                                title="Luciana Cremonese | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Dal dolore alla rinascita: il potere del lavoro | Luciana Cremonese</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="IwNlwGRNG1U"
                                title="Mario Mazzer | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>L’architettura determina la qualità della nostra esistenza | Mario Mazzer</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="bkmqdilw3JQ"
                                title="Roberto Bertazzon | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Come Rane sorde... | Roberto Bertazzon</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="2g-dQ4gNRT0"
                                title="Alessandra Patelli | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>La formula magica non esiste | Alessandra Patelli</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="0gmc7mWIKyM"
                                title="Vittoria Ferragamo | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Rallentare per riscoprire e riscoprirsi | Vittoria Ferragamo</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="mxF-iByTH48"
                                title="Sara Sech | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>La cura come patrimonio | Sara Sech</p>
                        </Col>
                        <Col sm={6} md={6} lg={4} className='mt-4'>
                            <LiteYouTubeEmbed
                                id="uFv8l4iX-Ik"
                                title="Carlos Veloso Dos Santos | TEDxConegliano"
                                poster="maxresdefault"
                            />
                            <p className='mt-2 small'>Felicità e sostenibilità: il futuro del lavoro | Carlos Veloso Dos Santos</p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} lg={5}>
                            <h5 style={{ color: '#eb0028' }}>TEAM</h5>
                            <h3 className='mt-5 mb-5'>La nostra squadra</h3>

                            <p className='lead mb-5'>
                                Il nostro team è una fusione dinamica di
                                giovani talenti provenienti da diverse sfere
                                della culturali e professionali
                            </p>
                            <p>
                                Guidati dalla passione per il territorio, l'innovazione e il
                                cambiamento, insieme, formiamo un gruppo diversificato e
                                complementare, unito dalla missione di ispirare,
                                condividere idee che trasformano il nostro territorio per il
                                meglio.
                            </p>

                            <div className='mt-5 mb-5'>
                                <Link to="/team">
                                    <span className='button-52 m-auto'>
                                        Il Team
                                    </span>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={6} className='text-center'>
                            <Image src={teamTED} width={'100%'} />
                        </Col>
                    </Row>
                </Container>
            </Container>


            {/*
            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} lg={5}>
                            <h5 style={{color:'#eb0028'}}>PARTNER</h5>
                            
                            <h3 className='mt-5 mb-5'>
                                I nostri partner, credono nel territorio e nei talenti che esso esprime.
                            </h3>
                            <p>
                                Con il loro sostegno, costruiamo insieme un futuro più
                                luminoso, in cui la cultura è al centro del progresso e della
                                crescita della comunità
                            </p>

                            <div className='mt-5 mb-5'>
                                <Link to="/partner">
                                    <span className='button-52 m-auto'>
                                        I partner
                                    </span>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={6} className='p-4 text-center'>
                            <Row>
                                {logos0.map((logo, i) => {
                                    return (
                                        <Col xs={6} md={4} className='p-3' key={i}>
                                            <Image src={logo} width={'100%'} />
                                        </Col>
                                    )
                                })}
                            </Row>
                            <Row>
                                {logos1.map((logo, i) => {
                                    return (
                                        <Col xs={4} md={3} className='p-3' key={i}>
                                            <Image src={logo} width={'100%'} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            */}


            <Container fluid className='pt-5 pb-0 ps-0 pe-0' style={{ background: '#eb0028' }}>
                <Container fluid="md" className='text-start p-5 text-white'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5>TEAM</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <p className='lead'>Unisciti anche tu alla nostra squadra e costruiamo qualcosa di unico</p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p className='pb-5'>
                                Sostieni concretamente le nostre idee e fatti coinvolgere per diventare un attore principale del cambiamento
                            </p>

                            <div className='mt-5 mb-5'>
                                <Link to="/contact">
                                    <span className='button-52-light m-auto'>
                                        Contatti
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    )

};