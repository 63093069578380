import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Pagination, Row, Col, Form } from 'react-bootstrap';
import { ApiManager } from '../../core/api-manager';
import { Helmet } from 'react-helmet';
import moment from 'moment';


export default function Blog() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p)

    const [data, setData] = useState(null);
    const [totalPages, setTotalPages] = useState(0);


    useEffect(() => {
        init()
    }, [page]);

    
    async function init() {
        let body = {
            page: page - 1
        }
        var response = await ApiManager.sendRequest('/public/get_blog', body)
        console.warn(response)
        if(response.success === 1) {
            setData(response.body)
            setTotalPages(response.pages)
        }
        else {
            setErrors('Errore. Riprova più tardi.')
        }
    }

    function changePage(p) {
        navigate('/blog/' + p)
    }

    function goToPrevPage() {
        if(page > 1) {
            changePage(page-1)
        }
    }
    function goToNextPage() {
        if(page < totalPages) {
            changePage(page+1)
        }
    }
    function goToFirstPage() {
        if(page !== 1) {
            changePage(1)
        }
    }
    function goToLastPage() {
        if(page !== totalPages) {
            changePage(totalPages)
        }
    }

    function openEntry(item) {
        navigate('/entry/' + item.id)
    }

    function searchValue(e) {
        e.preventDefault();
        console.log(e.target[0].value)
        navigate('/search/1', {
            state: {
                query: e.target[0].value
            }
        })
    }



    if (data !== null) {
        return (
            <>
                <Helmet>
                    <title>{'Blog - ' + page}</title>
                </Helmet>

                <Container fluid='md' className='mt-5 mb-5 text-center'>
                    <h1>Ultime notizie</h1>

                    <Row className='mt-5 mb-5'>
                        {data.map((item, i) => {
                            return(
                                <Col key={i} xs={12} md={6} lg={4} className='mb-4'>
                                    <Card onClick={() => openEntry(item)} role='button' className='border-0 text-start'>
                                        <Card.Body>
                                            <Card.Title>
                                                <span className='entry-cal'>
                                                    <span className='entry-cal-month'>{moment(item.date).format('MMM')}</span>
                                                    <span className='entry-cal-day'>{moment(item.date).format('DD')}</span>
                                                </span>
                                                {item.title}
                                            </Card.Title>
                                            <Card.Text className='small'>{item.date && moment(item.date).format('DD/MM/YYYY HH:mm')}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                    
                                </Col>
                            )
                        })}
                    </Row>
                    
                    <Row>
                        <Col xs={12} md={6}>
                            <Form onSubmit={(e) => searchValue(e)}>
                                <Form.Group className="mb-3">
                                    <Form.Control size="lg" type="text" placeholder="search" />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xs={12} md={6} className='d-flex justify-content-end'>
                            <Pagination size="lg">
                                <Pagination.First className={page === 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                                <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                                <Pagination.Item>
                                    {page}
                                </Pagination.Item>
                                <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                                <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner size="lg" animation="border" variant="secondary" />
                    <p className='text-secondary'>Loading</p>
                </div>
            </Container>
        )
    }
};