import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import home1 from '../assets/castello2.jpg';
import LucaBellotto from '../assets/LucaBellotto.jpg';
import MariavittoriaPadoin from '../assets/MariavittoriaPadoin.jpg';
import MarcoCekada from '../assets/MarcoCekada.jpg';
import MattiaCenedese from '../assets/MattiaCenedese.jpg';
import NicolaPiccoli from '../assets/NicolaPiccoli.jpg';
import preview from '../assets/preview.jpg';


export default function Team() {

    return (
        <>
            <Helmet>
                <title>Il Team di TEDxConegliano</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-red align-items-end' style={{backgroundColor:'#eee', backgroundImage:'url('+home1+')'}}>
                <Container fluid="md" className='text-start p-5 text-white' style={{zIndex: 1}}>
                    <h5>TEAM</h5>
                    <h1 className='mt-5 mb-5' style={{maxWidth:700}}>
                        Lʼunione fa la forza
                    </h1>

                    <p className='lead' style={{maxWidth:700}}>
                        Il nostro team è una fusione dinamica di giovani talenti provenienti
                        da diverse sfere della culturali e professionali
                    </p>

                    <div className='mt-5 mb-5'>
                        <a href='mailto:info@tedxconegliano.it' target='_blank'>
                            <span className='button-52-light m-auto'>
                                Entra anche tu
                            </span>
                        </a>
                    </div>

                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5 style={{color:'#eb0028'}}>TEAM</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <h3 className='mb-5'>
                                Una squadra unita dallo stesso obiettivo!
                            </h3>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p className='lead mb-5'>
                                Il nostro team è una fusione dinamica di
                                giovani talenti provenienti da diverse sfere
                                della culturali e professionali
                            </p>
                            <p>
                                Guidati dalla passione per il territorio, l'innovazione e il
                                cambiamento, insieme, formiamo un gruppo diversificato e
                                complementare, unito dalla missione di ispirare,
                                condividere idee che trasformano il nostro territorio per il meglio.
                            </p>

                            <div className='mt-5 mb-5'>
                                <a href='mailto:info@tedxconegliano.it' target='_blank'>
                                    <span className='button-52 m-auto'>
                                        Entra anche tu
                                    </span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0' style={{backgroundColor:'#eee'}}>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={3} className='pb-5'>
                            <h5 style={{color:'#eb0028'}}>TEAM</h5>
                            <p>Tutti con competenze trasversali uniti verso lo stesso obiettivo</p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={8} className='pb-5'>
                            <Row>
                                <Col xs={6} lg={4}>
                                    <Image src={LucaBellotto} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Luca Bellotto</p>
                                    <p className='text-muted'>Licensee TEDxConegliano</p>
                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={MariavittoriaPadoin} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Mariavittoria Padoin</p>
                                    <p className='text-muted'>Organizzazione Generale</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={MattiaCenedese} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Mattia Cenedese</p>
                                    <p className='text-muted'>Visual & Brand Designer</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={MarcoCekada} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Marco Cekada</p>
                                    <p className='text-muted'>Web Developer</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={preview} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Marco Poveglian</p>
                                    <p className='text-muted'>Team comunicazione</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={NicolaPiccoli} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Nicola Piccoli</p>
                                    <p className='text-muted'>Filmmaker</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={preview} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Marco Anzil</p>
                                    <p className='text-muted'>Team evento</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={preview} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Silvia Cortesia</p>
                                    <p className='text-muted'>Team partner</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={preview} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Federica Chioatto</p>
                                    <p className='text-muted'>Team evento</p>
                                </Col>
                                <Col xs={6} lg={4} className='mb-5'>
                                    <Image src={preview} width={'100%'} />
                                    <p style={{color:'#eb0028'}} className='lead mt-4 mb-1'>Veronica Calesso</p>
                                    <p className='text-muted'>Team evento</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-0 ps-0 pe-0' style={{background:'#eb0028'}}>
                <Container fluid="md" className='text-start p-5 text-white'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5>TEAM</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <p className='lead'>Unisciti anche tu alla nostra squadra e costruiamo qualcosa di unico</p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p className='pb-5'>
                                Sostieni concretamente le nostre idee e fatti coinvolgere per diventare un attore principale del cambiamento
                            </p>

                            <div className='mt-5 mb-5'>
                                <Link to="/contact">
                                    <span className='button-52-light m-auto'>
                                        Contatti
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    );
};