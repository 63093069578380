import React, { useState, useEffect } from 'react';
import './css/style.css';
import './css/header.css';
import './css/footer.css';
import './css/blog.css';
import './css/custom.css';
import './config/bootstrap.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'


/** pages */
import Home from './pages/index';
import NotFound from './pages/not_found';
import Team from './pages/team';
import Partner from './pages/partner';
import Contact from './pages/contact';
import CookiePolicy from './pages/cookie_policy';
import Blog from './pages/blog/index';
import BlogSearch from './pages/blog/search/index';
import Entry from './pages/entry/index';
import Heritage from './pages/edition/heritage';


import HeaderBlock from './components/HeaderBlock';
import FooterBlock from './components/FooterBlock';


import { Helmet } from "react-helmet";
import { settings } from './config/settings';
import { ParallaxProvider } from 'react-scroll-parallax';



function App() {

    library.add(fas)


    useEffect(() => {
        async function init() {
            
        }

        init()  
    }, []);
    


    return (
        <ParallaxProvider>
            <Router basename={'/'}>
                <div id="app-container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{settings.title}</title>
                        <meta name="description" content={settings.description} />
                    </Helmet>
                    <HeaderBlock />
                    <Routes>
                        <Route exact path='/' element={<Home />} /> {/* default page */}
                        <Route path="*" element={<NotFound />} /> {/* 404 page */}
                        <Route path='/team' element={<Team />} />
                        <Route path='/partner' element={<Partner />} />
                        <Route path='/edition/heritage' element={<Heritage />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/cookie_policy' element={<CookiePolicy />} />
                        <Route path="/blog/:p" element={<Blog />} />
                        <Route path="/search/:p" element={<BlogSearch />} />
                        <Route path="/entry/:k" element={<Entry />} />
                    </Routes>
                    <FooterBlock />
                </div>
            </Router>
        </ParallaxProvider>
    );
   
    
}

export default App;