import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import home1 from '../assets/conegliano.jpg';


export default function Contact() {

    return (
        <>
            <Helmet>
                <title>Contatti TEDxConegliano</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-red align-items-end' style={{backgroundColor:'#eee', backgroundImage:'url('+home1+')'}}>
                <Container fluid="md" className='text-start p-5 text-white' style={{zIndex: 1}}>
                    <h5 style={{fontWeight:600}}>CONTATTI</h5>
                    <h1 className='mt-5 mb-5' style={{maxWidth:700}}>
                        TEDxConegliano:<br/>
                        Lʼevento che può portare innovazione
                    </h1>

                    <p className='lead' style={{maxWidth:700}}>
                        Scrivici per restare in contatto con noi!<br/>
                        Vuoi entrare nella squadra? Vuoi diventare Partner o Tedder? O
                        molto semplicemente hai delle idee che possono far crescere la
                        communità grazie a TED? Scrivici
                    </p>

                    <div className='mt-5 mb-5'>
                        <a href='mailto:info@tedxconegliano.it' target='_blank'>
                            <span className='button-52-light m-auto'>
                                CONTATTACI
                            </span>
                        </a>
                    </div>

                </Container>
            </Container>

            {/*
            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={4} className='pb-5'>
                            <h5>GENERALE</h5>
                            <p className='text-muted small mt-4'>
                                Per domande di carattere generale scrivi a:
                                info@tedxconegliano.it
                            </p>
                        </Col>
                        <Col xs={12} lg={4} className='pb-5'>
                            <h5>TEAM</h5>
                            <p className='text-muted small mt-4'>
                                Siamo sempre alla ricerca di nuovi volontari:
                                volontari@tedxconegliano.it
                            </p>
                        </Col>
                        <Col xs={12} lg={4} className='pb-5'>
                            <h5>PARTNER</h5>
                            <p className='text-muted small mt-4'>
                                Vuoi essere un partner di tedxconegliano?
                                info@tedxconegliano.it
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            */}

        </>
    );
};