import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import home1 from '../assets/home2.jpg';



export default function Partner() {


    const cache0 = {};
    function importAll0(r) {
        r.keys().forEach((key) => (cache0[key] = r(key)));
    }
    importAll0(require.context("../assets/heritage/00_Main_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos0 = Object.entries(cache0).map(module => module[1]);


    const cache1 = {};
    function importAll1(r) {
        r.keys().forEach((key) => (cache1[key] = r(key)));
    }
    importAll1(require.context("../assets/heritage/01_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos1 = Object.entries(cache1).map(module => module[1]);


    const cache2 = {};
    function importAll2(r) {
        r.keys().forEach((key) => (cache2[key] = r(key)));
    }
    importAll2(require.context("../assets/heritage/02_Partner/", false, /\.(png|jpe?g|svg)$/));
    const logos2 = Object.entries(cache2).map(module => module[1]);


    
    return (
        <>
            <Helmet>
                <title>I Partner di TEDxConegliano</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0 bg-slide height-100 slide-gradient-red align-items-end' style={{backgroundColor:'#eee', backgroundImage:'url('+home1+')'}}>
                <Container fluid="md" className='text-start p-5 text-white' style={{zIndex: 1}}>
                    <h5>PARTNER</h5>
                    <h1 className='mt-5 mb-5' style={{maxWidth:700}}>
                        Prendi parte al cambiamento!
                    </h1>

                    <p className='lead' style={{maxWidth:700}}>
                        Attraverso sfide e innovazioni, ci dobbiamo far ispirare, costruendo
                        una comunità di pensatori audaci che arricchiscono il tessuto
                        culturale e sociale del nostro territorio
                    </p>

                    <div className='mt-5 mb-5'>
                        <a href='mailto:info@tedxconegliano.it' target='_blank'>
                            <span className='button-52-light m-auto'>
                                Diventa partner
                            </span>
                        </a>
                    </div>

                </Container>
            </Container>


            <Container fluid className='pt-5 pt-5 ps-0 pe-0'>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5 style={{color:'#eb0028'}}>PARTNER</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <h3 className='mb-5'>
                                I nostri partner, credono
                                nel territorio e nei talenti che esso esprime.
                            </h3>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p className='lead mb-5'>
                                Da subito abbracciano con entusiasmo la
                                nostra visione e credono nel potenziale
                                trasformativo della crescita culturale. Sono
                                collaboratori che non solo investono nelle
                                nostre iniziative, ma condividono la nostra
                                passione per l'innovazione e la conoscenza.
                            </p>
                            <p>
                                Con il loro sostegno, costruiamo insieme un futuro più
                                luminoso, in cui la cultura è al centro del progresso e della
                                crescita della comunità
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0' style={{background:'#eb0028'}}>
                <Container fluid="md" className='text-center p-5 text-white'>
                    <h5>TEDxConegliano</h5>
                    <h1 className='mt-5 mb-5 ms-auto me-auto' style={{maxWidth:760}}>
                        Grazie a chi ha deciso di contribuire alla crescita dellʼevento e allʼimpatto sulle persone
                    </h1>

                    <p className='m-auto' style={{maxWidth:700}}>
                        Insieme siamo una squadra pronta a costruire una nuova cultura
                    </p>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0'>
                <Container fluid="md" className='text-center p-5'>
                    <h5 style={{color:'#eb0028'}}>I NOSTRI PARTNER</h5>
                    
                    <Row className='justify-content-center'>
                        {logos0.map((logo, i) => {
                            return (
                                <Col xs={6} md={4} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>

                    <hr/>

                    <Row className='justify-content-center'>
                        {logos1.map((logo, i) => {
                            return (
                                <Col xs={6} md={3} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>

                    <hr/>

                    <Row className='justify-content-center'>
                        {logos2.map((logo, i) => {
                            return (
                                <Col xs={4} md={2} className='p-3' key={i}>
                                    <Image src={logo} width={'100%'} />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-0 ps-0 pe-0' style={{background:'#eee'}}>
                <Container fluid="md" className='text-start p-5'>
                    <Row className='align-items-start'>
                        <Col xs={12} lg={2} className='pb-5'>
                            <h5>PARTNER</h5>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={3} className='pb-5'>
                            <p className='lead'>Unisciti anche tu alla nostra squadra e costruiamo qualcosa di unico</p>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className='pb-5'>
                            <p className='pb-5'>
                                Sostieni concretamente le nostre idee e fatti coinvolgere per diventare un attore principale del cambiamento
                            </p>

                            <div className='mt-5 mb-5'>
                                <Link to="/contact">
                                    <span className='button-52 m-auto'>
                                        Be a partner
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    );
};