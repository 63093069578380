import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import notfound from '../assets/404.png';


export default function NotFound() {

    return (
        <>
            <Helmet>
                <title>Pagina non trovata in TEDxConegliano</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid='md' className='mt-5 mb-5 text-center'>

                <h1>Errore 404</h1>
                <h2 className='lead'>La pagina che stai cercando non esiste.</h2>

                <Image src={notfound} className="mt-5" width={'300'} style={{maxWidth:600}} />

                <div className='mt-5 mb-5 text-center'>
                    <Link to="/">
                        <span className='button-52 m-auto'>
                            Vai alla homepage
                        </span>
                    </Link>
                </div>

            </Container>
        </>
    );
};