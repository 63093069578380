import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, Image, Row, Col } from 'react-bootstrap';
import logo from '../../assets/TEDxConegliano_01_Black.png';



export default function HeaderBlock() {

    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
        var element = document.getElementById("app-container");
        element.setAttribute("data-page", location.pathname);
    }, [location]);

    
    useLayoutEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        var element = document.querySelector("#header .navbar-toggler") 
        if(element) {
            if(element.classList.contains('collapsed')) {
                //menu già chiuso
            }
            else {
                element.click()
            }
        }
    }, [location.pathname]);



    return (
        <Container fluid id="header">
            <Row className='align-items-center'>
                <Col xs={12} lg={3} className='d-none d-lg-block'>
                    <Container fluid={'lg'} className='pt-1 pb-1 ps-5 pe-5 text-dark overflow-hidden text-center text-lg-start'>
                        <Link to="/">
                            <Image src={logo} width={150} />
                        </Link>
                    </Container>
                </Col>
                <Col xs={12} lg={6}>
                    <Navbar expand="md" id='header-navbar' className='p-0'>
                        <Container fluid={'lg'}>
                            <span className='mobile-logo d-lg-none d-xs-block justify-content-center align-items-center' style={{height:85}}>
                                <Link to="/">
                                    <Image src={logo} width={120} />
                                </Link>
                            </span>
                            
                            <Navbar.Toggle className="mt-4 mb-4 ms-auto me-auto" aria-controls="navbarScroll" id="menu-button" />
                            <Navbar.Collapse id="navbarScroll" className='justify-content-center text-center'>
                                <Nav>
                                    <Nav.Link to="/" as={Link} className={location && location.pathname === '/' ? 'active' : ''}>
                                        Homepage
                                    </Nav.Link>
                                    <Nav.Link to="/edition/heritage" as={Link} className={location && location.pathname === '/edition/heritage' ? 'active' : ''}>
                                        Evento
                                    </Nav.Link>
                                    <Nav.Link to="/partner" as={Link} className={location && location.pathname === '/partner' ? 'active' : ''}>
                                        Partner
                                    </Nav.Link>
                                    <Nav.Link to="/team" as={Link} className={location && location.pathname === '/team' ? 'active' : ''}>
                                        Team
                                    </Nav.Link>
                                    <Nav.Link to="/contact" as={Link} className={location && location.pathname === '/contact' ? 'active' : ''}>
                                        Contatti
                                    </Nav.Link>
                                    {/*
                                    <Nav.Link to="/blog/1" as={Link}>
                                        Blog
                                    </Nav.Link>
                                    */}
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Col>
                <Col xs={12} lg={3} className='d-none d-lg-block'>
                    <div className='text-end pe-5'>
                        {/*
                        <a href="https://bit.ly/4bp5DAk" target="_blank">
                            <span className='button-52 m-auto'>
                                Biglietti
                            </span>
                        </a>
                        */}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};